.niceNumberEditor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.niceNumberEditor input {
  width: 40px;
  height: 20px;
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 10px;
  color: white;
}

input[type="number"]::-webkit-inner-spin-button {
  /* -webkit-appearance: none; */
}
