

.root {

    position: relative;
    width: 100%;
    padding-top: 50px;
    /* height: calc(100vh-50px); */
  
    background-color: black;
    color: white;
  
    /* font-family: lato; */
  
  }
  
  
  @media only screen and (max-width: 600px) {
    .root {
      padding-top: 0px !important;
    }
  }
  
  
  .fadeIn {
    animation: fadeIn ease 4s;
    -webkit-animation: fadeIn ease 4s;
    -moz-animation: fadeIn ease 4s;
    -o-animation: fadeIn ease 4s;
    -ms-animation: fadeIn ease 4s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
  }
  
  
  .fadeQuick {
    animation: fadeQuick ease 1s;
    -webkit-animation: fadeQuick ease 1s;
    -moz-animation: fadeQuick ease 1s;
    -o-animation: fadeQuick ease 1s;
    -ms-animation: fadeQuick ease 1s;
  }
  @keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-moz-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-webkit-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-o-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
  @-ms-keyframes fadeQuick {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
  }
  }