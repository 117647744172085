


.simCanvasWrapper {
    width: 100%;
    height: 100%;


    position: relative;
    border-radius: 8px;

    touch-action: none;
}

.simCanvasWithBorder {
    border: 1px solid rgba(255, 255, 255, 0.2);
}


.simCanvas {
    width: 100%;
    height: 100%;
}