

.loadSaveModal {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid blue;
    width: 50%;
    height: 50%;

    z-index: 100;

}

.bodyContent {
    padding: 20px;
}

.saveBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saveBody input {
    all: unset;

    margin: 50px 0px;
    padding: 16px;

    border: 1px solid orangered;

}

.buttonFooter {
    display: flex;
    flex-direction: row;
    gap: 8px;
}


.buttonFooter button {
    all: unset;

    padding: 10px 20px;
    background-color: rgb(50, 50, 50);
    color: white;
    border-radius: 8px;
    opacity: 0.6;
    transition: ease-in-out 0.2s all;
    cursor: pointer;
}
.buttonFooter button:hover {
    opacity: 1.0;
}

.patternPreview {
    display: flex;
    flex-direction: row;

    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid orangered;
    /* border-radius: 8px; */
    padding: 4px;

    justify-content: space-between;
    align-items: center;

    /* border: 1px solid aqua; */

    margin: 20px 0px;
}

.patternPreview button {
    all: unset;

    padding: 12px 8px;
    margin-right: 10px;
    background-color: rgb(50, 50, 50);
    color: white;
    border-radius: 8px;
    opacity: 0.6;
    transition: ease-in-out 0.2s all;
    cursor: pointer;
    
}

.savedNamePreview {
    display: flex;
    gap: 8px;
    align-items: center;
}

.savedNamePreview > :first-child {

}

.savedNamePreview > :last-child {
    background-color: orangered;
    padding: 8px;
    border-radius: 6px;
    opacity: 0.4;
}

.header {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
}

.header .title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    opacity: 0.8;
    letter-spacing: 0.2em;
    font-weight: unset;

    padding: 10px 0px;
}

.headerOptRow {
    display: flex;
    flex-direction: row;
    /* align-items: ; */
    justify-content: space-around;
}

.headerOptRow h2 {
    all: unset;
    cursor: pointer;
    opacity: 0.6;
    font-size: 20px;
    letter-spacing: 0.4em;
}

.headerOptRow h2:hover {
    opacity: 1;
}


.noSaveDisclaimer {
    color:rgb(155, 155, 155)
}

.closeWrapper {
    position: absolute;
    top: 10px;
    right: 10px;

    cursor: pointer;
    opacity: 0.4;

}

.closeWrapper:hover {
    opacity: 1;
}

.closeWrapper img {
    filter: invert();
    width: 30px;
    height: 30px;
}


@media only screen and (max-width: 800px) {
    .loadSaveModal {
        width: calc(100vw - 20px);
        height: calc(100% - 100px);
    }
}

  