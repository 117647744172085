

.Header{
    position: fixed;

    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    /* margin-bottom: 100px; */
    /* background-color: rgba(0,0,0, 0.85); */
    color: rgb(255, 255, 255);
    /* opacity: 65%; */

    /* padding-top: 10px;
    padding-left: 10px; */
    /* font-family: 'lato'; */
    font-size: 20px;
    

    z-index: 5;

    cursor: default;

    transition: height 0.15s linear ;
}

.Logo {
    height: 80%;
    
    position: relative;
    top: 10%;
    left: 35px;
}

.LogoText {
    height: 25px;
    padding-top: 13px;


    /* line-height: 50px; */
    margin-left: 20px;
    letter-spacing: 5px;



    float: left;
    cursor: pointer;
    transition: ease-in-out 0.3s all;

    /* filter: invert(1); */
    
}

.LogoText:hover {
    color: rgb(172, 185, 241);
    filter: invert(.5);

}

.HeaderTitle {
    display: inline-block;
    float: left;
    transition: ease-in-out 0.3s all;
}

.HeaderTitle:hover {
    color: rgb(172, 185, 241);
    filter: invert(.5);

}

.HeaderOption {
    float: right;
    margin: 0px;
    margin-right: 25px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    letter-spacing: 5px;
    
}

.HeaderOption:hover {
    /* color: rgb(172, 185, 241);
    filter: invert(.5); */
    opacity: 0.7;
}

.HeaderIcon {
    height: 60%;
    position: relative;
    top: 20%;
    padding-right: 10px;
    padding-left: 10px;
}

.HeaderIcon img {
    /* width: 100%; */
    height: 100%;
}

.headerIndicator2 {
    position: absolute;
    white-space: normal;
    bottom: -5px;
    right: -5px;
    /* width: 200px; */
    
    height: 20px;

    padding: 20px;

    /* border-radius: 100px; */
    /* margin: 0px; */
    /* text-align: center; */
    transform: translateY(-50%);

    background-color: white;
    font-size: 12px;
    /* color: black; */
}

.headerIndicator {
    position: absolute;
    bottom: -5px;
    right: -5px;

    border-radius: 50%;
    width: 12px;
    height: 12px;
    padding: 4px;

    background: #fff;
    /* border: 2px solid #666; */
    color: black;
    text-align: center;

    font: 12px Arial, sans-serif;
}
.headerIndicatorCartCountText{
    padding-left: 1px;
    padding-top: -1px;

    transform: translate(2px, -1px);
    /* border: turquoise solid 2px; */
    text-align: center;
}

.HeaderIcon:hover {
    opacity: 0.7;
}

.activeHeaderIcon {
}

.HeaderActive {
    color: rgb(172, 185, 241);
}

.HeaderDropdown {
    position: fixed; 
    top: 50px;
    display: none;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.85);
    width: 200px;
    color: white;
    /* font-family: "lato"; */

    transition: height 0.15s linear ;
}


.headerOpener {
    /* display: none; */
    float: right;
    margin: 0px;
    margin-right: 25px;
    /* height: 80px; */
    line-height: 50px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    height: 70%;
    position: relative;
    top: 15%;
}

.saveStateButton {
    /* display: none; */
    float: right;
    margin: 0px;
    margin-right: 25px;
    /* height: 80px; */
    line-height: 50px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    height: 70%;
    position: relative;
    top: 15%;
    filter: invert();
}


.navBar {
    height: calc(100vh - 50px);
    position: fixed;
    top: 50px;
    right: 0;
    background-color: rgba(0,0,0, 0.8);
    width: 0px;
    transition: ease-in-out 0.3s all;
    border-left: 1px solid rgba(0,0,0,0);

}

.navBarOpen {
    width: 200px;
    z-index: 50;
    border-left: 1px solid white;

}

.navOpt {
    /* text-align: center; */
    padding: 10px 0px 0px 8px;
    font-size: 16px;
}

.navOptActive {
    color: rgb(172, 185, 241);
}

.navBarExiter {
    z-index: 49;
    width: 100vw;
    height: 100vh;
}




/* starting ENTER animation */
.messageout-enter {
    height: 0px;
    display: block;
}

/* ending ENTER animation */
.messageout-enter-active {
    /* opacity: 1; */
    height: 100px;
    transition: all 500ms linear;
}

/* starting EXIT animation */
.messageout-exit {
    height: 100px;
}

/* ending EXIT animation */
.messageout-exit-active {
    height: 0px;
    transition: all 500ms ease-in-out;
}

.loadingCircle {
    width: 20px;
    height: 20px; 
    border-radius: 50%;
    transition: none;

    top: 50%;
    transform: translateY(-50%);
}

.loadingCircle_loadingg {
    background-color: yellow !important;
}
.loadingCircle_failed {
    background-color: red;
}
.loadingCircle_loaded {
    background-color: green;
}

.navBarSection {
    padding: 0px 8px;

}

.navBarSection > h2 {
    font-size: 20px;
}

.navBarSectionInner {
}


@media only screen and (max-width: 800px) {
    .HeaderOption {
        display: none !important;
    }
    .headerOpener {
        display: block;
    }

    .navBarOpen {
        width: 150px;
    }

    .Header .headerOpener {
      float: right;
      display: block;
    }
}

  