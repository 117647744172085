.midiCenterer {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.midiKeyboard {
  height: 200px;
  width: auto;
  display: flex;
  border: 1px solid wheat;
}

.keyboardKey {
  text-align: center;
}

.whiteKey {
  width: 50px;
  height: 100%;
  background-color: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  color: rgba(0, 0, 0, 0.667);
}

.blackKey {
  position: relative;
  float: left;
  width: 40px;
  height: 80%;
  background-color: red;
  color: rgba(255, 255, 255, 0.557);
}

.controlPanel {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);

  height: auto;
  width: 400px;

  padding: 20px;

  border: 2px solid rgba(245, 222, 179, 0.29);
}

.settingsIcon {
  /* filter: invert() */
  width: 20px;
  height: 20px;
  filter: invert(1);
  padding-top: 20px;
  opacity: 0.7;
  transition: ease-in-out 0.2s all;
}
.settingsIcon:hover {
  opacity: 1;
}

@media only screen and (max-width: 800px) {
  .midiCenterer {
  }

  .controlPanel {
    width: calc(100% - 100px);
  }

  .whiteKey {
    width: 25px;
  }

  .blackKey {
    width: 20px;
  }

  .midiKeyboard {
    height: 80px;
  }
}

.samplePathRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.samplePathRow > input {
  height: 30px;
  width: 50px;
}

.samplePathRow > button {
  background-color: red;
  color: white;
}

.addPathSegmentButton {
  background-color: aquamarine;
  color: black;
}

.pressedKey {
  background-color: aquamarine !important;
}
