.settingsSidebar {
  height: calc(100vh - 70px);
  position: fixed;
  top: 50px;
  left: -200px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 200px;
  transition: ease-in-out 0.3s all;
  border-right: 1px solid rgba(0, 0, 0, 0);
  /* overflow: hidden; */
  opacity: 0;
}

.settingsSidebarOpen {
  left: 0px;
  z-index: 50;
  border-right: 1px solid white;
  opacity: 1;
}
